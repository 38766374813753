import * as React from 'react'
import loadable from '@loadable/component'

const FormPage = loadable(() => import('/src/templates/FormPage'))

const Page = ({ postInfo }) => {
    const post = {
        canonical: '/over50-critical-illness',
        title: 'Compare <span>Critical Illness</span> Quotes - from £23.03/Month*'
    }

    return <FormPage postInfo={postInfo} post={post} />
}

export default Page
